<template>
<div>
  <div class="success_pop_wrap" @click="close"></div>
    <div class="success_pop">
      <div class="success">
        <div class="success_close" @click="close">
          <img src="../assets/images/close.svg" />
        </div>
        <div class="tips">{{type=='baodian' || type=='baodian2'?'下载成功':'上传成功'}}</div>
        <p class="shengyu" v-if="type=='baodian' || type=='baodian2'">您今日剩余<template v-if="showarr[0]">字体素材下载次数：{{zt_num}}</template><template v-if="showarr[0] && showarr[1]">，</template><template v-if="showarr[1]">变形宝典下载次数：{{bd_num}}</template></p>
        <p class="shengyu" v-else></p>
        <div class="recommend">
          <!-- <div class="recommend_head" @click="toVideo">
            <div class="recommend_title">更多字体设计方法视频</div>
            <div class="recommend_link">查看更多>>></div>
          </div> -->
          <div class="rec_list">
            <ul>
              <li
                v-for="(item, index) of list"
                :key="index"
                @click="toVideoDetail(item.id)"
              >``
                <div class="img"><img v-lazy="item.video_thumb" /></div>
              </li>
            </ul>
          </div>
        <div class="btn" @click="toVideo">一站学会字体设计</div>
        <p class="desc">从基础到高阶，多种字体技能一站get</p>

        </div> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "successPop",
  props:['type','showarr'],
  data() {
    return {
        list:[],
        zt_num:0,
        bd_num:0
    };
  },
  mounted() {
    this.randomFun();
    if(this.type=='baodian' || this.type=='baodian2'){
      this.download_number();
    }
  },
  methods: {
    randomFun() {
      var that = this;
      var params = {
        nums: 3,
      };
      this.$api.POST(this.$face.video_random, params, function (res) {
        that.list = res.data;
      });
    },
    download_number() {
      var that = this;
 
      this.$api.POST(this.$face.downloadZipdowncount, {}, function (res) {
        that.zt_num = res.data.zt_num;
        that.bd_num = res.data.bd_num;
      });
    },
    close(){
        this.$parent.randomState=false;
    },
    toVideo(){
      let routeData = this.$router.resolve({
        name: "video"
      });
      window.open(routeData.href, "_blank");
    },
    toVideoDetail(id){
      let routeData = this.$router.resolve({
        name: "videoDetail",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    }
  },
};
</script>

<style scoped>
.success_pop_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.success_pop {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 500px;
  background: #fff;
  border-radius: 30px;
  z-index: 1000;
}
.success {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 43px 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.success_close {
  position: absolute;
  top: 24px;
  right: 24px;
}
.success_close img {
  width: 17px;
  height: 17px;
  display: inline-block !important;
  cursor: pointer;
  opacity: 0.4;
}
.tips {
  display: block;

  margin: 40px auto 6px;
  width: 130px;
  padding-top: 58px;
  background-image: url("../assets/images/success.png");
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: top center;
  font-size: 24px;
  color: #4d4d4d;
  font-weight: 500;
  text-align: center; letter-spacing: 1px;
}
.success p.shengyu{ font-size: 12px; height: 12px; line-height: 12px; color: #999999; margin-bottom: 32px; text-align: center;letter-spacing: 1px;}
.success p.desc{ font-size: 14px; color: #999999; text-align: center;letter-spacing: 1px;}
.recommend_head {
  height: 22px;
  line-height: 22px;
}
.recommend_title {
  float: left;
  font-size: 22px;
  transition: 0.2s;
  cursor: pointer;
}
.recommend_link {
  float: right;
  color: #b3b3b3;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
}
.recommend_title:hover,
.recommend_link:hover {
  color: #5857ff;
}
.rec_list {
  min-height: 160px;
}
.rec_list ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.rec_list li {
  width: 225px;
  height: 175px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}
.rec_list li .img {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.rec_list li .img img {
  height: 100%;
  transition: 0.5s;
}
.rec_list li .img img:hover {
  transform: scale(1.05);
}
.btn{ width: 278px; height: 56px; border-radius: 30px; background: #5957ff;transition: 0.3s; text-align: center; line-height: 56px; font-size: 22px; color: #fff; cursor: pointer; margin:40px auto 11px;}
.btn:hover{ transform: scale(1.03);}
</style>